import classNames from 'classnames';
import { FC } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ILinkProps } from './ILinkProps';

export const Link: FC<ILinkProps> = ({ children, variant, href, className, target }) => {
	const getClasses = () => {
		const unstyled = variant === 'unstyled';
		const isExternalLink = variant === 'external';
		const isNavLink = variant === 'nav';

		return unstyled
			? className
			: classNames('usa-link', { 'usa-link--external': isExternalLink, 'usa-nav__link': isNavLink }, className);
	};

	const classes = getClasses();

	return (
		<ReactRouterLink className={classes} to={href} target={target}>
			{children}
		</ReactRouterLink>
	);
};
