import {
	Banner,
	BannerButton,
	BannerContent,
	BannerFlag,
	BannerGuidance,
	BannerHeader,
	BannerIcon,
	Icon,
	MediaBlockBody,
} from '@trussworks/react-uswds';
import { FC, useState } from 'react';
import { DotGovIcon, HttpsIcon, USFlag } from '../../assets';

export const UsaBanner: FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const onToggle = () => setIsOpen(previousIsOpen => !previousIsOpen);

	return (
		<Banner aria-label="Official website of the state department of something specific">
			<BannerHeader
				isOpen={isOpen}
				flagImg={<BannerFlag src={USFlag} aria-hidden alt="" />}
				headerText="An official website of the United States government"
				headerActionText="Here's how you know"
			>
				<BannerButton isOpen={isOpen} aria-controls="custom-banner" onClick={onToggle}>
					Here&apos;s how you know
				</BannerButton>
			</BannerHeader>
			<BannerContent id="custom-banner" isOpen={isOpen}>
				<div className="grid-row grid-gap-lg">
					<BannerGuidance className="tablet:grid-col-6">
						<BannerIcon src={DotGovIcon} alt="" />
						<MediaBlockBody>
							<p>
								<strong>Official websites use .gov</strong>
								<br />A <strong>.gov</strong> website belongs to an official government organization in
								the United States.
							</p>
						</MediaBlockBody>
					</BannerGuidance>
					<BannerGuidance className="tablet:grid-col-6">
						<BannerIcon src={HttpsIcon} alt="" />
						<MediaBlockBody>
							<p>
								<strong>Secure .gov websites use HTTPS</strong>
								<br />A{' '}
								<strong>
									lock (<Icon.Lock aria-label="Locked padlock icon" />)
								</strong>{' '}
								or <strong>https://</strong> means you&apos;ve safely connected to the .gov website.
								Share sensitive information only on official, secure websites.
							</p>
						</MediaBlockBody>
					</BannerGuidance>
				</div>
			</BannerContent>
		</Banner>
	);
};
