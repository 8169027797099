import React, { useState, useEffect } from 'react';
import { Table, Button, Alert } from '@trussworks/react-uswds';
import DocumentService from '../services/DocumentService';
import { CaseDocument } from '../types';

interface CaseDocumentsProps {
	caseId: string;
	clientId: string;
}

const CaseDocuments: React.FC<CaseDocumentsProps> = ({ caseId, clientId }) => {
	const [documents, setDocuments] = useState<CaseDocument[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		fetchDocuments();
	}, [caseId]);

	const fetchDocuments = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const docs = await DocumentService.getDocumentsForCase(caseId);
			setDocuments(docs);
		} catch (error) {
			console.error('Error fetching documents:', error);
			setError('Failed to fetch documents. Please try again.');
		} finally {
			setIsLoading(false);
		}
	};

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('caseId', caseId);
			formData.append('clientId', clientId);
			formData.append('documentType', file.type);

			try {
				await DocumentService.uploadDocument(formData);
				fetchDocuments(); // Refresh the document list
			} catch (error) {
				console.error('Error uploading document:', error);
				setError('Failed to upload document. Please try again.');
			}
		}
	};

	if (isLoading) {
		return <div>Loading documents...</div>;
	}

	return (
		<div className="case-documents">
			<h2>Case Documents</h2>
			{error && (
				<Alert type="error" heading="Error" headingLevel="h4">
					{error}
				</Alert>
			)}
			<input type="file" onChange={handleFileUpload} />
			<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Created At</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{documents.map(doc => (
						<tr key={doc.documentId}>
							<td>{doc.name}</td>
							<td>{doc.documentType}</td>
							<td>{new Date(doc.createdAt).toLocaleString()}</td>
							<td>
								<Button type={'button'} onClick={() => window.open(doc.fullUrl, '_blank')}>
									View
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default CaseDocuments;
