import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const DefaultDashboard: React.FC = () => {
	const { userInfo } = useAuth();

	const getRoleMessage = () => {
		if (!userInfo?.role || userInfo.role === 'default') {
			return "You don't have a specific role assigned yet.";
		}
		return `Your role (${userInfo.role}) doesn't have a specific dashboard assigned.`;
	};

	return (
		<div>
			<h1>Welcome to RIVERDOCK</h1>
			<p>Hello, {userInfo?.name || userInfo?.username || 'User'}!</p>
			<p>{getRoleMessage()}</p>
			<p>If you believe this is an error, please contact your system administrator.</p>
		</div>
	);
};

export default DefaultDashboard;
