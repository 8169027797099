export interface AppConfig {
	BASE_API_URL: string;
	ENVIRONMENT: string;
	WEB_DISPLAY_NAME: string;
}

export function getAppConfig(): AppConfig {
	return {
		BASE_API_URL: '', // This will be set in index.ts
		ENVIRONMENT: '', // This will be set in index.ts
		WEB_DISPLAY_NAME: '', // This will be set in index.ts
	};
}

export const appConfig: AppConfig = getAppConfig();
