import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getDashboardRouteForRole } from '../utils/routeUtils';

const RoleBasedRedirect: React.FC = () => {
	const { userInfo } = useAuth();

	if (!userInfo) {
		return <div>Loading...</div>;
	}

	const dashboardRoute = getDashboardRouteForRole(userInfo.role);
	return <Navigate to={dashboardRoute} replace />;
};

export default RoleBasedRedirect;
