import { FC } from 'react';
import { LogoSvg } from '../../assets';
import { ILogoProps } from './ILogoProps';
import './Logo.scss';
import classNames from 'classnames';

export const Logo: FC<ILogoProps> = ({
	showText = false,
	imageWidth,
	fontSize = '21px',
	lineHeight = '21px',
	letterSpacing = '0.21px',
	fontWeight = 500,
	className = '',
}) => {
	return (
		<div className={classNames('logo-container display-flex flex-align-center', className)}>
			<img alt="United States Citizenship and Immigration Services Logo" src={LogoSvg} width={imageWidth} />
			{showText && (
				<span
					className="text"
					style={{
						fontSize,
						lineHeight,
						letterSpacing,
						fontWeight,
					}}
				></span>
			)}
		</div>
	);
};
