import React, { useEffect, useState } from 'react';
import { Grid, Card, Table, Button, Alert } from '@trussworks/react-uswds';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ApiService from '../services/ApiService';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface DashboardData {
	teamMembership: {
		id: string;
		name: string;
		role: string;
		status: string;
		casesAssigned: number;
		casesClosed: number;
		casesOpen: number;
	}[];
	caseSummary: {
		totalCases: number;
		openCases: number;
		closedCases: number;
		closedThisMonth: number;
	};
	caseDistribution: {
		caseType: string;
		openCases: number;
		closedCases: number;
	}[];
}

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
	active?: boolean;
	payload?: {
		value: number;
		name: string;
		dataKey: string;
	}[];
	label?: string;
}

const CaseManagerDashboard: React.FC = () => {
	const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function fetchDashboardData() {
			setIsLoading(true);
			setError(null);
			try {
				const response = await ApiService.request<DashboardData>({
					method: 'GET',
					url: '/v1/users/dashboard/case-manager',
				});
				setDashboardData(response);
			} catch (err) {
				setError('Failed to fetch dashboard data. Using mock data.');
				setDashboardData(getMockDashboardData());
			} finally {
				setIsLoading(false);
			}
		}

		fetchDashboardData();
	}, []);

	const generateReport = () => {
		console.log('Generating report...');
	};

	if (isLoading) {
		return <div>Loading dashboard...</div>;
	}

	if (!dashboardData) {
		return <div>No data available</div>;
	}

	const formatYAxis = (tickItem: number) => {
		return Math.abs(tickItem).toString();
	};

	const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div
					className="custom-tooltip"
					style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}
				>
					<p className="label">{`${label}`}</p>
					<p style={{ color: '#82ca9d' }}>{`Open Cases: ${payload[0]?.value}`}</p>
					<p style={{ color: '#8884d8' }}>{`Closed Cases: ${Math.abs(payload[1]?.value || 0)}`}</p>
				</div>
			);
		}
		return null;
	};

	const getYAxisDomain = () => {
		if (!dashboardData) return [0, 0];
		const maxValue = Math.max(
			...dashboardData.caseDistribution.map(item => Math.max(item.openCases, item.closedCases))
		);
		return [-maxValue, maxValue];
	};

	return (
		<div className="case-manager-dashboard">
			<h1>Office Operations Dashboard</h1>

			{error && (
				<Alert type="warning" heading="Data Fetch Error" headingLevel={'h2'}>
					{error}
				</Alert>
			)}

			<Grid row gap>
				<Grid tablet={{ col: 6 }}>
					<Card>
						<h2>Team Membership</h2>
						<Table bordered striped>
							<thead>
								<tr>
									<th>Name</th>
									<th>Role</th>
									<th>Status</th>
									<th>Assigned</th>
									<th>Closed</th>
									<th>Open</th>
								</tr>
							</thead>
							<tbody>
								{dashboardData.teamMembership.map(member => (
									<tr key={member.id}>
										<td>{member.name}</td>
										<td>{member.role}</td>
										<td>{member.status}</td>
										<td>{member.casesAssigned}</td>
										<td>{member.casesClosed}</td>
										<td>{member.casesOpen}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card>
				</Grid>

				<Grid tablet={{ col: 6 }}>
					<Card>
						<h2>Case Summary</h2>
						<p>Total Cases: {dashboardData.caseSummary.totalCases}</p>
						<p>Open Cases: {dashboardData.caseSummary.openCases}</p>
						<p>Closed Cases: {dashboardData.caseSummary.closedCases}</p>
						<p>Cases Closed This Month: {dashboardData.caseSummary.closedThisMonth}</p>
					</Card>
				</Grid>
			</Grid>

			<Grid row className="margin-top-2">
				<Grid col={12}>
					<Card>
						<h2>Case Distribution by Type</h2>
						<ResponsiveContainer width="100%" height={400}>
							<BarChart
								data={dashboardData.caseDistribution.map(item => ({
									...item,
									closedCases: -item.closedCases, // Make closed cases negative
								}))}
								margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="caseType" />
								<YAxis tickFormatter={formatYAxis} domain={getYAxisDomain()} allowDecimals={false} />
								<Tooltip content={<CustomTooltip />} />
								<Legend />
								<Bar dataKey="openCases" fill="#82ca9d" name="Open Cases" />
								<Bar dataKey="closedCases" fill="#8884d8" name="Closed Cases" />
							</BarChart>
						</ResponsiveContainer>
					</Card>
				</Grid>
			</Grid>

			<Grid row className="margin-top-2">
				<Grid col={12}>
					<Card>
						<h2>Generate Operational Reports</h2>
						<Button type="button" onClick={generateReport}>
							Generate CSV Report
						</Button>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};

const getMockDashboardData = (): DashboardData => ({
	teamMembership: [
		{
			id: '1',
			name: 'John Doe',
			role: 'Case Worker',
			status: 'Active',
			casesAssigned: 50,
			casesClosed: 10,
			casesOpen: 40,
		},
		{
			id: '2',
			name: 'Jane Smith',
			role: 'Case Worker',
			status: 'On Leave',
			casesAssigned: 40,
			casesClosed: 15,
			casesOpen: 25,
		},
		{
			id: '3',
			name: 'Bob Johnson',
			role: 'Senior Case Worker',
			status: 'Active',
			casesAssigned: 60,
			casesClosed: 20,
			casesOpen: 40,
		},
	],
	caseSummary: {
		totalCases: 200,
		openCases: 150,
		closedCases: 50,
		closedThisMonth: 30,
	},
	caseDistribution: [
		{ caseType: 'Type A', openCases: 50, closedCases: 20 },
		{ caseType: 'Type B', openCases: 40, closedCases: 15 },
		{ caseType: 'Type C', openCases: 60, closedCases: 15 },
	],
});

export default CaseManagerDashboard;
