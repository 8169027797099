import React from 'react';
import { Footer, Header, UsaBanner } from './components';
import AppRouter from './pages/app.routes';
import { BrowserRouter } from 'react-router-dom';
import { ProvideAuth } from './contexts/AuthContext';

export const App: React.FC = () => {
	return (
		<ProvideAuth>
			<BrowserRouter>
				<div className="app__layout">
					<UsaBanner />
					<Header />
					<main id="main-content" className="app__content">
						<AppRouter />
					</main>
					<Footer />
				</div>
			</BrowserRouter>
		</ProvideAuth>
	);
};

export default App;
