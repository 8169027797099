export const getDashboardRouteForRole = (role: string): string => {
	switch (role) {
		case 'case_worker':
			return '/case-worker-dashboard';
		case 'case_manager':
			return '/case-manager-dashboard';
		default:
			return '/default-dashboard';
	}
};

export const getAppropriateRoute = (isAuthenticated: boolean, userRole: string | undefined): string => {
	if (!isAuthenticated) {
		return '/login';
	}
	return userRole ? getDashboardRouteForRole(userRole) : '/login';
};
