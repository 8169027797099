import { useState, useEffect } from 'react';
import ApiService from '../../services/ApiService';

interface UseApiResult<T> {
	data: T | null;
	error: Error | null;
	isLoading: boolean;
}

export function useApi<T>(config: { method: string; url: string; params?: unknown; body?: unknown }): UseApiResult<T> {
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await ApiService.request<T>({
					...config,
					url: `${ApiService.getFullBaseUrl()}${config.url}`,
				});
				setData(result);
			} catch (err) {
				setError(err as Error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [JSON.stringify(config)]);

	return { data, error, isLoading };
}
