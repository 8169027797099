// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import ConfigService from './services/ConfigService';
import { getAppConfig } from './config';
import ApiService from './services/ApiService';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const initializeApp = async () => {
	await ConfigService.loadConfig();
	const externalConfig = ConfigService.getConfig();

	const appConfig = getAppConfig();
	appConfig.BASE_API_URL = externalConfig.api.endpoint;
	appConfig.ENVIRONMENT = externalConfig.environment;
	appConfig.WEB_DISPLAY_NAME = externalConfig.web.displayName;

	ApiService.initialize(appConfig);

	Amplify.configure({
		Auth: {
			Cognito: {
				userPoolId: externalConfig.cognito.userPoolId,
				userPoolClientId: externalConfig.cognito.clientId,
			},
		},
	});

	root.render(<App />);
};

initializeApp().catch(error => {
	console.error('Failed to initialize app:', error);
	root.render(<div>Failed to load application configuration.</div>);
});

reportWebVitals();
