// src/hooks/useCaseStatusHistory.ts
import { useState, useEffect, useCallback } from 'react';
import ApiService from '../services/ApiService';

import { CASE_API_VERSION } from '../constants';

interface StatusHistoryItem {
	id: string;
	status: string;
	createdAt: string;
	createdBy: string;
	actionType: string;
}

interface StatusHistoryResponse {
	caseId: string;
	history: StatusHistoryItem[];
}

export const useCaseStatusHistory = (caseId: string) => {
	const [data, setData] = useState<StatusHistoryResponse | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchStatusHistory = useCallback(async () => {
		if (!caseId) return;
		setIsLoading(true);
		try {
			const result = await ApiService.request<StatusHistoryResponse>({
				method: 'GET',
				url: `${ApiService.getFullBaseUrl()}/${CASE_API_VERSION}/cases/${caseId}/status-history`,
			});
			setData(result);
			setError(null);
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, [caseId]);

	const updateStatus = useCallback(
		async (newStatus: string) => {
			if (!caseId) return;
			try {
				await ApiService.request({
					method: 'PUT',
					url: `${ApiService.getFullBaseUrl()}/${CASE_API_VERSION}/cases/${caseId}/status`,
					data: { status: newStatus },
				});
				await fetchStatusHistory();
			} catch (err) {
				setError(err as Error);
				throw err;
			}
		},
		[caseId, fetchStatusHistory]
	);

	useEffect(() => {
		fetchStatusHistory();
	}, [fetchStatusHistory]);

	return { data, error, isLoading, fetchStatusHistory, updateStatus };
};
