import React, { useState } from 'react';
import { Alert, Button, Table } from '@trussworks/react-uswds';
import { useAssignedCases } from '../hooks/useAssignedCases';
import { CaseData } from '../types';
import { Link } from 'react-router-dom';

const CaseWorkerDashboard: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const [showAlert, setShowAlert] = useState(false);

	const { data, error, isLoading, assignNextCase } = useAssignedCases(currentPage);

	const handleNextCase = async () => {
		try {
			const newCase = await assignNextCase();
			if (newCase) {
				setAlertMessage('New case assigned successfully.');
			} else {
				setAlertMessage('No cases available in the queue.');
			}
			setShowAlert(true);
		} catch (error) {
			setAlertMessage('Error assigning new case. Please try again.');
			setShowAlert(true);
		}
	};

	if (isLoading) return <div>Loading assigned cases...</div>;
	if (error) return <div>Error loading cases: {error.message}</div>;

	return (
		<div>
			<h1>Case Worker Dashboard</h1>

			{showAlert && alertMessage && (
				<Alert type="info" heading={alertMessage} headingLevel="h4">
					<Button type="button" onClick={() => setShowAlert(false)}>
						Dismiss
					</Button>
				</Alert>
			)}

			<Button type="button" onClick={handleNextCase}>
				Assign Next Available Case
			</Button>

			<h2>Your Assigned Cases</h2>
			<Table bordered striped>
				<thead>
					<tr>
						<th>Case ID</th>
						<th>Client ID</th>
						<th>Case Type</th>
						<th>Status</th>
						<th>Open Date</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{data?.cases.map((caseItem: CaseData) => (
						<tr key={caseItem.caseId}>
							<td>{caseItem.caseId}</td>
							<td>{caseItem.clientId}</td>
							<td>{caseItem.caseType}</td>
							<td>{caseItem.status}</td>
							<td>{new Date(caseItem.openDate).toLocaleDateString()}</td>
							<td>
								<Link to={`/case/${caseItem.caseId}`}>
									<Button type="button">View Case</Button>
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			{data && data.totalPages > 1 && (
				<div>
					<Button
						type="button"
						onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
						disabled={currentPage === 1}
					>
						Previous
					</Button>
					<span>
						Page {currentPage} of {data.totalPages}
					</span>
					<Button
						type="button"
						onClick={() => setCurrentPage(prev => Math.min(prev + 1, data.totalPages))}
						disabled={currentPage === data.totalPages}
					>
						Next
					</Button>
				</div>
			)}
		</div>
	);
};

export default CaseWorkerDashboard;
