// src/hooks/useAssignedCases.ts
import { useState, useEffect, useCallback } from 'react';
import ApiService from '../services/ApiService';
import { CASE_API_VERSION } from '../constants';
import { CaseData } from '../types';

interface AssignedCasesResponse {
	cases: CaseData[];
	totalCount: number;
	currentPage: number;
	totalPages: number;
}

export const useAssignedCases = (page = 1, limit = 10) => {
	const [data, setData] = useState<AssignedCasesResponse | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchAssignedCases = useCallback(async () => {
		setIsLoading(true);
		try {
			const result = await ApiService.request<AssignedCasesResponse>({
				method: 'GET',
				url: `${ApiService.getFullBaseUrl()}/${CASE_API_VERSION}/cases/assigned?page=${page}&limit=${limit}`,
			});
			setData(result);
			setError(null);
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, [page, limit]);

	const assignNextCase = useCallback(async () => {
		try {
			const newCase = await ApiService.request<CaseData>({
				method: 'POST',
				url: `${ApiService.getFullBaseUrl()}/${CASE_API_VERSION}/cases/next`,
			});
			if (newCase && data) {
				setData(prevData => ({
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					...prevData!,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					cases: [newCase, ...prevData!.cases],
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					totalCount: prevData!.totalCount + 1,
				}));
			}
			return newCase;
		} catch (err) {
			setError(err as Error);
			throw err;
		}
	}, [data]);

	useEffect(() => {
		fetchAssignedCases();
	}, [fetchAssignedCases]);

	return { data, error, isLoading, fetchAssignedCases, assignNextCase };
};
