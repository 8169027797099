// src/services/ConfigService.ts

interface AppConfig {
	api: {
		endpoint: string;
	};
	cognito: {
		userPoolId: string;
		clientId: string;
	};
	web: {
		domainName: string;
		displayName: string;
	};
	document: {
		domainName: string;
	};
	environment: string;
}

class ConfigService {
	private static instance: ConfigService | null = null;
	private config: AppConfig | null = null;

	// Private constructor to prevent direct construction calls with the `new` operator.
	private constructor() {
		// Initialization if needed
	}

	public static getInstance(): ConfigService {
		if (!ConfigService.instance) {
			ConfigService.instance = new ConfigService();
		}
		return ConfigService.instance;
	}

	public async loadConfig(): Promise<void> {
		try {
			const response = await fetch('/config.json');
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			this.config = await response.json();
		} catch (error) {
			console.error('Failed to load config:', error);
			throw error;
		}
	}

	public getConfig(): AppConfig {
		if (!this.config) {
			throw new Error('Config not loaded. Call loadConfig() first.');
		}
		return this.config;
	}

	public isConfigLoaded(): boolean {
		return this.config !== null;
	}
}

export default ConfigService.getInstance();
