// src/services/DocumentService.ts

import ApiService from './ApiService';
import ConfigService from './ConfigService';
import { CaseDocument } from '../types';

class DocumentService {
	private static instance: DocumentService;
	private documentDomain: string | null = null;

	private constructor() {
		this.getDocumentsForCase = this.getDocumentsForCase.bind(this);
	}

	public static getInstance(): DocumentService {
		if (!DocumentService.instance) {
			DocumentService.instance = new DocumentService();
		}
		return DocumentService.instance;
	}

	private async ensureConfigLoaded() {
		if (!ConfigService.isConfigLoaded()) {
			await ConfigService.loadConfig();
		}
		if (this.documentDomain === null) {
			const config = ConfigService.getConfig();
			this.documentDomain = config.document.domainName;
		}
	}

	async getDocumentsForCase(caseId: string): Promise<CaseDocument[]> {
		await this.ensureConfigLoaded();
		try {
			const response = await ApiService.request<CaseDocument[]>({
				method: 'GET',
				url: `/v1/documents/case/${caseId}`,
			});
			return response.map(this.addFullUrl);
		} catch (error) {
			console.error('Error fetching documents:', error);
			throw error;
		}
	}

	private addFullUrl = (document: CaseDocument): CaseDocument => {
		return {
			...document,
			name: document.name || document.documentPath.split('/').pop() || 'Unnamed Document',
			fullUrl: `https://${this.documentDomain}/${document.documentPath}`,
		};
	};

	async uploadDocument(formData: FormData): Promise<CaseDocument> {
		await this.ensureConfigLoaded();
		try {
			const response = await ApiService.request<CaseDocument>({
				method: 'POST',
				url: '/v1/documents',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return this.addFullUrl(response);
		} catch (error) {
			console.error('Error uploading document:', error);
			throw error;
		}
	}
}

export default DocumentService.getInstance();
