// src/hooks/useSearchCase.ts

import { useState } from 'react';
import ApiService from '../services/ApiService';
import { CASE_API_VERSION } from '../constants';
import { CaseData } from '../types';

export const useSearchCase = () => {
	const [isSearching, setIsSearching] = useState(false);
	const [searchError, setSearchError] = useState<string | null>(null);

	const searchCase = async (caseId: string): Promise<CaseData | null> => {
		setIsSearching(true);
		setSearchError(null);

		try {
			const result = await ApiService.request<CaseData>({
				method: 'GET',
				url: `${ApiService.getFullBaseUrl()}/${CASE_API_VERSION}/cases/${caseId}`,
			});

			return result;
		} catch (error) {
			setSearchError('Error searching for case. Please try again.');
			return null;
		} finally {
			setIsSearching(false);
		}
	};

	return { searchCase, isSearching, searchError };
};
