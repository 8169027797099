import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Grid, GridContainer, Table } from '@trussworks/react-uswds';
import StatusUpdate from './StatusUpdate';
import CaseHistory from './CaseHistory';
import { useApi } from '../hooks/useApi';
import { useCaseStatusHistory } from '../hooks/useCaseStatusHistory';

import { CASE_API_VERSION } from '../constants';
import { CaseData } from '../types';
import CaseDocuments from './CaseDocuments';

const CaseDetail: React.FC = () => {
	const { caseId } = useParams<{ caseId: string }>();
	const {
		data: caseData,
		error: caseError,
		isLoading: caseLoading,
	} = useApi<CaseData>({
		method: 'GET',
		url: `/${CASE_API_VERSION}/cases/${caseId}`,
	});
	const {
		data: statusHistory,
		error: statusHistoryError,
		isLoading: statusHistoryLoading,
		updateStatus,
	} = useCaseStatusHistory(caseId || '');
	const [currentStatus, setCurrentStatus] = useState<string>('');
	const [updateMessage, setUpdateMessage] = useState<string | null>(null);

	useEffect(() => {
		if (caseData) {
			setCurrentStatus(caseData.status);
		}
	}, [caseData]);

	const handleStatusUpdate = async (newStatus: string) => {
		try {
			await updateStatus(newStatus);
			setCurrentStatus(newStatus);
			setUpdateMessage('Status updated successfully');
		} catch (error) {
			setUpdateMessage('Failed to update status. Please try again.');
		}
	};

	if (caseLoading) return <div aria-live="polite">Loading case information...</div>;
	if (caseError)
		return (
			<div aria-live="assertive" role="alert">
				Error loading case: {caseError.message}
			</div>
		);
	if (!caseData)
		return (
			<div aria-live="assertive" role="alert">
				No case data found
			</div>
		);

	return (
		<main id="main-content" tabIndex={-1}>
			<GridContainer className="usa-section">
				<h1>Case Information</h1>
				{updateMessage && (
					<Alert
						type={updateMessage.includes('Failed') ? 'error' : 'success'}
						heading={updateMessage}
						headingLevel="h4"
					/>
				)}
				<Grid row gap>
					<Grid desktop={{ col: 8 }}>
						<section aria-labelledby="case-details-heading">
							<h2 id="case-details-heading">Case Details</h2>
							<Table bordered striped>
								<caption className="usa-sr-only">Case details</caption>
								<tbody>
									<tr>
										<th scope="row">Case ID</th>
										<td>{caseData.caseId}</td>
									</tr>
									<tr>
										<th scope="row">Client ID</th>
										<td>{caseData.clientId}</td>
									</tr>
									<tr>
										<th scope="row">Case Type</th>
										<td>{caseData.caseType}</td>
									</tr>
									<tr>
										<th scope="row">Current Status</th>
										<td>{currentStatus}</td>
									</tr>
									<tr>
										<th scope="row">Open Date</th>
										<td>{new Date(caseData.openDate).toLocaleDateString()}</td>
									</tr>
									<tr>
										<th scope="row">Close Date</th>
										<td>
											{caseData.closeDate
												? new Date(caseData.closeDate).toLocaleDateString()
												: 'N/A'}
										</td>
									</tr>
								</tbody>
							</Table>
						</section>
					</Grid>
					<Grid>
						<CaseDocuments caseId={caseData.caseId} clientId={caseData.clientId} />
					</Grid>
					<Grid desktop={{ col: 4 }}>
						<StatusUpdate currentStatus={currentStatus} onStatusUpdate={handleStatusUpdate} />
					</Grid>
				</Grid>
				<Grid row className="margin-top-4">
					<Grid col={12}>
						{statusHistoryLoading ? (
							<div>Loading status history...</div>
						) : statusHistoryError ? (
							<div>Error loading status history: {statusHistoryError.message}</div>
						) : statusHistory ? (
							<CaseHistory history={statusHistory.history} />
						) : (
							<div>No status history available</div>
						)}
					</Grid>
				</Grid>
			</GridContainer>
		</main>
	);
};

export default CaseDetail;
