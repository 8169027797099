import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AppConfig } from '../config';

class ApiService {
	private static instance: ApiService;
	private api: AxiosInstance | null = null;
	private baseUrl = '';

	private constructor() {
		//Initialization if needed
	}

	public static getInstance(): ApiService {
		if (!ApiService.instance) {
			ApiService.instance = new ApiService();
		}
		return ApiService.instance;
	}

	public initialize(config: AppConfig) {
		this.baseUrl = config.BASE_API_URL;

		let fullBaseUrl = `${this.baseUrl}`;

		if (!fullBaseUrl.startsWith('http')) {
			fullBaseUrl = window.location.protocol + '//' + fullBaseUrl;
		}

		this.api = axios.create({
			baseURL: fullBaseUrl,
		});

		console.log('fullBaseUrl' + fullBaseUrl);
		this.api.interceptors.request.use(async config => {
			try {
				const { tokens } = await fetchAuthSession();
				const token = tokens?.idToken?.toString();
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			} catch (error) {
				console.error('Error getting auth token:', error);
			}
			return config;
		});
	}

	getFullBaseUrl(): string {
		let fullBaseUrl = this.baseUrl;
		if (!fullBaseUrl.startsWith('http')) {
			fullBaseUrl = window.location.protocol + '//' + fullBaseUrl;
		}

		return fullBaseUrl;
	}

	async request<T>(config: AxiosRequestConfig): Promise<T> {
		if (!this.api) {
			throw new Error('ApiService not initialized');
		}
		try {
			const response = await this.api.request<T>(config);
			return response.data;
		} catch (error) {
			console.error('API request failed:', error);
			throw error;
		}
	}
}

export default ApiService.getInstance();
