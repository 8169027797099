import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, ButtonGroup, Checkbox, Grid, GridContainer, Label, TextInput } from '@trussworks/react-uswds';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LoginFields, LoginProps } from './types';

const Login: FC<LoginProps> = ({ type }) => {
	const navigate = useNavigate();
	const { signIn } = useAuth();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [loginError, setLoginError] = useState<boolean>(false);

	const { control, handleSubmit } = useForm<LoginFields>({
		mode: 'onSubmit',
	});

	const onSubmit = async (data: LoginFields) => {
		try {
			const success = await signIn(data.username, data.password);
			if (success) {
				navigate('/'); // Redirect to root, which will trigger RoleBasedRedirect
			} else {
				setLoginError(true);
			}
		} catch (_) {
			setLoginError(true);
		}
	};

	return (
		<div className="bg-base-lightest">
			<GridContainer className="usa-section">
				<Alert hidden={!loginError} type={'error'} headingLevel={'h2'} className="margin-bottom-3">
					Sorry, we couldn&apos;t find a account with the below information
				</Alert>
				<Grid row gap className="flex-justify-center">
					<Grid col={12} tablet={{ col: 8 }} desktop={{ col: 6 }}>
						<div className="bg-white padding-y-3 padding-x-5 border border-base-lighter">
							{(type === 'Provider' || type === 'Prescriber') && (
								<Alert type={'info'} headingLevel={'h2'} className="margin-bottom-3">
									This page is only used to showcase there can be multiple login pages for each type
									of user.
								</Alert>
							)}

							<h2 className="margin-bottom-0">Access your RIVERDOCK Account</h2>

							<div className="margin-top-4">
								<Button type="button">Smartcard Sign in</Button> Or enter your credentials:
							</div>

							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="username"
									control={control}
									rules={{ required: true }}
									render={({ field, fieldState }) => (
										<>
											<Label htmlFor={field.name}>
												Username <span className="text-red">*</span>
											</Label>
											<TextInput
												id={field.name}
												required
												type="text"
												autoComplete="username"
												name={field.name}
												onChange={event => field.onChange(event.target.value)}
											/>
											<small className="text-red">{fieldState.error?.message}</small>
										</>
									)}
								/>
								<Controller
									name="password"
									control={control}
									rules={{ required: true }}
									render={({ field, fieldState }) => (
										<>
											<Label htmlFor={field.name}>
												Password <span className="text-red">*</span>
											</Label>
											<TextInput
												id={field.name}
												required
												autoComplete="current-password"
												type={showPassword ? 'text' : 'password'}
												name={field.name}
												onChange={event => field.onChange(event.target.value)}
											/>
											<small className="text-red">{fieldState.error?.message}</small>
										</>
									)}
								/>
								<div className="padding-top-3">
									<p className="usa-form__note">
										<Button
											type="button"
											unstyled
											aria-controls="password-create-account password-create-account-confirm"
											onClick={(): void => setShowPassword(showPassword => !showPassword)}
										>
											Forgot password?
										</Button>
									</p>

									<Button disabled={false} type="submit">
										Sign In
									</Button>
								</div>

								<Checkbox
									className="margin-top-3"
									id="device-check"
									type="checkbox"
									name="device-check"
									value="device-check"
									label="Using a trusted device? Remember me for 12 hours."
								/>
							</form>
						</div>
					</Grid>
				</Grid>

				<Grid row gap className="flex-justify-center margin-top-3">
					<Grid col={12} tablet={{ col: 8 }} desktop={{ col: 6 }}>
						<div className="bg-white padding-y-3 padding-x-5 border border-base-lighter">
							<h1 className="margin-bottom-0">Partner Account Sign In</h1>

							<ButtonGroup type="default" className="flex-justify-center margin-top-3 margin-bottom-3">
								<Button type="button">Epic Sign In</Button>
								<Button type="button">RISS Sign In</Button>
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</GridContainer>
		</div>
	);
};

export default Login;
