import { Grid, Footer as UswdsFooter } from '@trussworks/react-uswds';
import { FC } from 'react';
import { Link, Logo } from '..';
import './Footer.scss';

export const Footer: FC = () => {
	const renderTopFooter = () => {
		return (
			<Grid
				row
				className="grid-container desktop:padding-y-205 padding-y-1 desktop:padding-x-4 display-flex flex-align-center"
			>
				<Logo
					showText
					imageWidth={100}
					fontSize={'40px'}
					lineHeight={'35px'}
					fontWeight={400}
					className="section"
				/>
				<Grid className="section">
					<div className="text-bold font-size-22">RIVERDOCK Helpdesk - Open 24/7</div>
					<div className="display-flex flex-justify-end font-size-16">
						<div>
							Tel:{' '}
							<Link href="tel:8000000000" className="contact-link" target="_blank">
								800-000-0000
							</Link>
						</div>
						<div className="padding-x-1" />
						<div>
							Email:{' '}
							<Link
								href="mailto:RIVERDOCK.Helpdesk@uscis.uscis.gov"
								className="contact-link"
								target="_blank"
							>
								RIVERDOCK Help Desk
							</Link>
						</div>
					</div>
				</Grid>
			</Grid>
		);
	};

	const renderBottomFooter = () => {
		const links = [{ name: 'About RIVERDOCK', href: 'https://www.uscis.gov/what-river' }];

		return (
			<div className="font-size-16">
				<Grid row className="logo-wrapper">
					<Logo imageWidth={48} />
					<div className="padding-left-1">
						<Link href="#" target="_blank">
							Login
						</Link>
						<div className="text-bold color-white">
							An official website of the{' '}
							<Link href="https://www.uscis.gov/" className="color-white" target="_blank">
								U.S. Citizenship and Immigration Services
							</Link>
						</div>
					</div>
				</Grid>
				<Grid row className="padding-y-2 links-wrapper">
					{links.map(({ href, name }) => (
						// Grid column styles are overwritten in usa-footer__secondary-section so you have to manually override the width instead of providing column #
						<Grid key={name} className="link">
							<Link href={href} target="_blank">
								{name}
							</Link>
						</Grid>
					))}
				</Grid>
				<Grid row className="color-white">
					Looking for U.S. government information and services?
					<span className="padding-left-05 text-bold">
						Visit
						<Link className="color-white padding-left-05" href="https://www.usa.gov/" target="_blank">
							USA.gov
						</Link>
					</span>
				</Grid>
			</div>
		);
	};

	return <UswdsFooter size="medium" primary={renderTopFooter()} secondary={renderBottomFooter()} />;
};
