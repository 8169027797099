import { GridContainer, Button } from '@trussworks/react-uswds';
import cardBgImg from '../../assets/card-bg-img.png';
import { FC } from 'react';

const articleContent1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis vitae orci sodales pharetra. Nam scelerisque libero ultrices ante vulputate blandit. In ut felis ut sem ullamcorper consectetur. Nulla libero nisi, placerat non iaculis et, porttitor in lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum vulputate ut ipsum sit amet finibus. Ut in nulla efficitur, varius quam vel, volutpat turpis. Cras sagittis facilisis purus quis porttitor. Donec a augue at nibh sagittis porttitor et non urna.
Vestibulum id nulla sed dui vehicula ullamcorper at vitae leo. Duis egestas, odio eget auctor pharetra, lectus augue accumsan libero, nec tincidunt tortor mi nec odio. Vivamus ac varius lacus. Etiam laoreet rutrum purus, vitae malesuada justo tincidunt vitae. Morbi eu ex enim. Proin aliquam hendrerit egestas. Vestibulum pellentesque nulla leo, et bibendum arcu pretium eget. Vestibulum rutrum neque et turpis cursus, in congue nisl placerat. Etiam sed ligula eget nulla mollis posuere eu vel massa. Suspendisse luctus tincidunt massa quis congue. Etiam vitae lectus eu nunc condimentum vestibulum. Vestibulum imperdiet congue facilisis. Maecenas ornare metus ac sodales imperdiet. Nulla et interdum enim. Cras tincidunt, enim eu tempus tristique, dui nibh ornare metus, in lacinia ligula massa non nibh.
Curabitur quis odio sapien. Suspendisse ac consequat ligula, vel iaculis ligula. Integer nec pharetra sapien, ac porta nulla. Vivamus sagittis porta leo, id mollis dui faucibus sed. Nulla commodo non libero vitae euismod. Morbi sagittis facilisis enim vitae cursus. Pellentesque quis mi et orci ullamcorper viverra. Vestibulum pharetra vel urna convallis sagittis. Nulla pretium, augue nec sodales gravida, massa nibh interdum sem, vitae dapibus metus nibh quis elit. Nullam quis sollicitudin urna, at tristique justo. Donec egestas ac ipsum sed pharetra. Quisque lacinia erat id risus consectetur ullamcorper.`;

const articleContent2 = `Cras in suscipit ex. Aenean eleifend lorem quis porta rutrum. Nulla sed quam neque. Etiam libero ligula, ultricies vitae lectus id, dignissim rutrum lectus. Nullam condimentum est non velit auctor aliquam. Vivamus in ligula tincidunt, consectetur justo nec, condimentum eros. Pellentesque elementum risus et accumsan ullamcorper.
Sed hendrerit lacus ac turpis placerat aliquet. Quisque aliquam risus nisi, ac interdum elit hendrerit in. Morbi porttitor vel urna eget eleifend. Curabitur id mauris sapien. Integer sit amet neque ac leo congue maximus quis a mauris. Morbi aliquam ligula in risus pulvinar molestie. Suspendisse in porta libero. Sed scelerisque interdum facilisis. Pellentesque id enim posuere, cursus erat non, consectetur nunc. Donec at semper magna. Nam accumsan, tortor at fermentum volutpat, quam felis dignissim odio, vel vestibulum sem mi vel turpis. Sed commodo quam quis nunc scelerisque eleifend. Cras faucibus magna ut sapien elementum tempus. Praesent risus lectus, aliquet non eros at, varius fringilla metus.
Nullam bibendum blandit metus eget maximus. Curabitur nec est non justo volutpat rhoncus. Fusce libero ex, tristique ac nisl eu, ullamcorper ornare metus. Fusce quis tortor congue, venenatis enim a, cursus ex. Donec magna lorem, facilisis et est in, volutpat feugiat lectus. Fusce ut augue posuere, imperdiet arcu sed, bibendum nunc. Morbi luctus consequat semper. Duis ligula dolor, scelerisque eu vestibulum nec, tincidunt eu ex. Aliquam vel massa nisi. Fusce quis diam quis tortor faucibus tempus. Quisque vel lectus eget ante ultrices sodales.
In tincidunt, metus ac ultrices finibus, ligula nisl venenatis urna, non pulvinar lectus lectus eu justo. Nullam ultrices eros ligula, vel interdum dui varius a. Suspendisse id mi elit. Maecenas arcu lorem, dignissim eu hendrerit at, sollicitudin ut est. Vestibulum eu massa volutpat, tincidunt massa et, feugiat orci. Fusce lacinia mauris quis ligula facilisis, eget sodales dolor finibus. In interdum tellus at metus efficitur, id congue libero placerat. In iaculis sed risus non finibus. Praesent varius dapibus nibh sit amet aliquam. Donec vitae quam cursus, pulvinar velit id, finibus nibh. Donec nec malesuada metus.`;

const Article: FC = () => {
	return (
		<div>
			<GridContainer className="usa-section">
				<h1 className="margin-top-0">Drugs Seizure At Boston Logan Airport</h1>
				<div className="flex-row">
					<h4>
						<span>Updated: xx/xx/xx 12:34</span>
						<span className="margin-left-4">Added: xx/xx/xx 12:34</span>
					</h4>
					<h4>
						By <span className="text-blue">Agent Name</span>
					</h4>
				</div>
				<div>
					<img src={cardBgImg} alt="" className="maxw-mobile-lg margin-right-4 float-left" />
					<p className="font-sans-md line-height-sans-6">{articleContent1}</p>
					<Button type="button" className="font-sans-sm">
						Report Name
					</Button>
					<p className="font-sans-md line-height-sans-6">{articleContent2}</p>
				</div>
			</GridContainer>
		</div>
	);
};

export default Article;
