import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import { AuthProps, UseAuth } from './types';

export interface UserInfo {
	username: string;
	email?: string;
	name?: string;
	role: string;
}

export const AuthContext = createContext({} as UseAuth);

export const useAuth = () => {
	return useContext(AuthContext);
};

const useProvideAuth = (): UseAuth => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [username, setUsername] = useState<string>('');
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
	const [roles, setRoles] = useState<string[]>([]);
	const [token, setToken] = useState<string>('');
	const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
	const [isAdjudicatorUser, setIsAdjudicatorUser] = useState<boolean>(false);

	useEffect(() => {
		checkAuthState();
	}, []);

	const checkAuthState = async () => {
		try {
			const user = await getCurrentUser();
			const { tokens } = await fetchAuthSession();
			await fetchUserAttributes();
			if (typeof tokens === 'undefined' || !tokens.accessToken || !tokens.idToken) {
				throw new Error('No tokens found');
			}

			setUsername(user.username);
			setIsAuthenticated(true);
			setToken(tokens.idToken.toString());

			let userRole = 'default';
			const profile = tokens.idToken.payload.profile as string;
			if (profile) {
				try {
					const profileData = JSON.parse(profile);
					userRole = profileData.role || 'default';
				} catch (error) {
					console.error('Error parsing profile attribute:', error);
				}
			}

			setUserInfo({
				username: tokens.idToken.payload['cognito:username'] as string,
				email: tokens.idToken.payload.email as string,
				name: `${tokens.idToken.payload.given_name} ${tokens.idToken.payload.family_name}`,
				role: userRole,
			});

			setIsAdminUser(userRole === 'admin');
			setIsAdjudicatorUser(userRole === 'adjudicator');

			setRoles([userRole]);
		} catch (error) {
			console.error('Error checking auth state:', error);
			clearAuthState();
		} finally {
			setIsLoading(false);
		}
	};

	const clearAuthState = () => {
		setIsAuthenticated(false);
		setUsername('');
		setToken('');
		setRoles([]);
		setIsAdminUser(false);
		setIsAdjudicatorUser(false);
		setUserInfo(null);
	};

	const signInUser = async (username: string, password: string) => {
		try {
			await signIn({ username, password });
			await checkAuthState();
			return true;
		} catch (error) {
			console.error('Error signing in:', error);
			return false;
		}
	};

	const signOutUser = async () => {
		try {
			await signOut();
			clearAuthState();
			return true;
		} catch (error) {
			console.error('Error signing out:', error);
			return false;
		}
	};

	return {
		isLoading,
		isAuthenticated,
		username,
		roles,
		token,
		isAdminUser,
		isAdjudicatorUser,
		userInfo,
		signIn: signInUser,
		signOut: signOutUser,
	};
};

export const ProvideAuth: React.FC<AuthProps> = ({ children }) => {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
