import React, { useState } from 'react';
import { Button, Label, Select } from '@trussworks/react-uswds';

interface StatusUpdateProps {
	currentStatus: string;
	onStatusUpdate: (newStatus: string) => Promise<void>;
}

const StatusUpdate: React.FC<StatusUpdateProps> = ({ currentStatus, onStatusUpdate }) => {
	const [newStatus, setNewStatus] = useState(currentStatus);
	const [isUpdating, setIsUpdating] = useState(false);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsUpdating(true);
		try {
			await onStatusUpdate(newStatus);
		} finally {
			setIsUpdating(false);
		}
	};

	return (
		<section aria-labelledby="status-update-heading">
			<h2 id="status-update-heading">Update Status</h2>
			<form onSubmit={handleSubmit}>
				<Label htmlFor="status-select">New Status</Label>
				<Select
					id="status-select"
					name="status"
					value={newStatus}
					onChange={e => setNewStatus(e.target.value)}
					className="margin-bottom-2"
					aria-describedby="status-description"
				>
					<option value="Pending">Pending</option>
					<option value="In Review">In Review</option>
					<option value="Approved">Approved</option>
					<option value="Rejected">Rejected</option>
				</Select>
				<div id="status-description" className="usa-sr-only">
					Select the new status for this case
				</div>
				<Button type="submit" disabled={isUpdating || newStatus === currentStatus}>
					{isUpdating ? 'Updating...' : 'Update Status'}
				</Button>
			</form>
		</section>
	);
};

export default StatusUpdate;
