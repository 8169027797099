import React from 'react';
import { Table } from '@trussworks/react-uswds';

interface StatusHistoryItem {
	id: string;
	status: string;
	createdAt: string;
	createdBy: string;
	actionType: string;
}

interface CaseHistoryProps {
	history: StatusHistoryItem[];
}

const CaseHistory: React.FC<CaseHistoryProps> = ({ history }) => {
	const sortedHistory = [...history].sort(
		(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
	);

	return (
		<section aria-labelledby="case-history-heading">
			<h2 id="case-history-heading">Case History</h2>
			<Table bordered striped fullWidth>
				<caption className="usa-sr-only">Case status history in reverse chronological order</caption>
				<thead>
					<tr>
						<th scope="col">Date</th>
						<th scope="col">Status</th>
						<th scope="col">Action</th>
						<th scope="col">Created By</th>
					</tr>
				</thead>
				<tbody>
					{sortedHistory.map(item => (
						<tr key={item.id}>
							<td>{new Date(item.createdAt).toLocaleString()}</td>
							<td>{item.status}</td>
							<td>{item.actionType || 'Status Updated'}</td>
							<td>{item.createdBy}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</section>
	);
};

export default CaseHistory;
