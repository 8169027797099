import { useEffect } from 'react';
import { IUseClickAwayProps } from './IUseClickAwayProps';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickAway = ({ ref, onClickAway }: IUseClickAwayProps) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onClickAway();
			}
		};
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};
