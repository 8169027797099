import { Route, Routes, Navigate } from 'react-router-dom';
import Article from './Article';
import FindAPerson from './FindAPerson';
import Login from './Login';
import ProtectedRoute from '../components/ProtectedRoute';
import CaseDetail from '../components/CaseDetail';
import RoleBasedRedirect from '../components/RoleBasedRedirect';
import CaseWorkerDashboard from '../components/CaseWorkerDashboard';
import CaseManagerDashboard from '../components/CaseManagerDashboard';
import DefaultDashboard from '../components/DefaultDashboard';
import { useAuth } from '../contexts/AuthContext';

interface Route<PathParams = null | undefined | Record<string, string>> {
	path: string;
	altPaths?: string[];
	page: JSX.Element;
	params?: PathParams;
}

export const routes = {
	login: { path: '/login', page: <Login type="" /> },
	article: { path: '/article/:articleId', page: <Article />, params: { articleId: '' } },
	findAPerson: { path: '/find-a-person', page: <FindAPerson /> },
	caseDetail: { path: '/case/:caseId', page: <CaseDetail />, params: { caseId: '' } },
	caseWorkerDashboard: { path: '/case-worker-dashboard', page: <CaseWorkerDashboard /> },
	caseManagerDashboard: { path: '/case-manager-dashboard', page: <CaseManagerDashboard /> },
	defaultDashboard: { path: '/default-dashboard', page: <DefaultDashboard /> },
};

export type RouteNames = keyof typeof routes;

export type RouteParams<RouteName extends RouteNames> = (typeof routes)[RouteName] extends { params: infer Params }
	? Params
	: null;

const AppRouter = () => {
	const { isAuthenticated } = useAuth();

	return (
		<Routes>
			<Route path="/login" element={isAuthenticated ? <Navigate to="/" replace /> : <Login type="" />} />
			<Route
				path="/"
				element={
					<ProtectedRoute>
						<RoleBasedRedirect />
					</ProtectedRoute>
				}
			/>
			{Object.entries(routes as Record<RouteNames, Route>).map(([name, { path, altPaths = [], page }]) => {
				const paths = [path, ...altPaths];

				return paths.map(path => (
					<Route
						key={`${name}-${path}`}
						path={path}
						element={
							name === 'login' ? (
								isAuthenticated ? (
									<Navigate to="/" replace />
								) : (
									page
								)
							) : (
								<ProtectedRoute>{page}</ProtectedRoute>
							)
						}
					/>
				));
			})}
		</Routes>
	);
};

export default AppRouter;
