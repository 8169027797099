import React, { FC, Fragment, useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ExtendedNav,
	Menu,
	NavDropDownButton,
	NavMenuButton,
	Search,
	Title,
	Header as UswdsHeader,
	Button,
	Alert,
} from '@trussworks/react-uswds';
import { Link, Logo } from '..';
import { useClickAway } from '../../hooks';
import { useSearchCase } from '../../hooks/useSearchCase';
import { useAuth } from '../../contexts/AuthContext';
import './Header.scss';
import { routes } from '../../pages/app.routes';

const primaryItems = [
	{
		name: 'Topics',
		subItems: [{ name: 'test link 1' }, { name: 'test link 2' }],
	},
	{
		name: 'News',
		subItems: [{ name: 'test link 3' }, { name: 'test link 4' }],
	},
	{
		name: 'I Want To...',
		subItems: [
			{ name: 'Book a training course' },
			{ name: 'Find a person, group or team', path: routes.findAPerson.path },
			{ name: 'Organize an online meeting' },
			{ name: 'Start a group' },
			{ name: 'Start a new document' },
			{ name: 'Submit an alert' },
			{ name: 'Upload content - Photos, PDFs, videos, etc.' },
		],
	},
];

const secondaryItems = [
	{
		name: 'People Finder',
		url: '/people-finder',
	},
	{
		name: 'My Favorites',
		url: '/favorites',
	},
	{
		name: 'Agent Name',
		url: '/agent-name',
	},
];

export const Header: FC = () => {
	const navigate = useNavigate();
	const { userInfo, signOut, isAuthenticated } = useAuth();
	const [expanded, setExpanded] = useState<number>();
	const [mobileExpanded, setMobileExpanded] = useState<boolean>(false);
	const { searchCase } = useSearchCase();
	const [showNotFound, setShowNotFound] = useState(false);
	const notFoundAlertRef = useRef<HTMLDivElement>(null);

	const extendedHeaderRef = useRef<HTMLDivElement>(null);
	useClickAway({
		ref: extendedHeaderRef,
		onClickAway: () => {
			setExpanded(undefined);
			if (mobileExpanded) setMobileExpanded(false);
		},
	});

	const onToggleExtendedHeader = (index?: number) => setExpanded(expanded === index ? undefined : index);
	const onToggleMobileExpanded = () => setMobileExpanded(prevState => !prevState);

	useEffect(() => {
		if (showNotFound && notFoundAlertRef.current) {
			notFoundAlertRef.current.focus();
		}
	}, [showNotFound]);

	const onSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
			event.preventDefault();
			const form = event.currentTarget;
			const searchInput = form.querySelector('input[type="search"]') as HTMLInputElement;
			const searchTerm = searchInput.value.trim();

			if (!searchTerm) return;

			setShowNotFound(false);
			const result = await searchCase(searchTerm);

			if (result) {
				navigate(`/case/${searchTerm}`);
			} else {
				setShowNotFound(true);
			}

			// Clear the search input after submission
			searchInput.value = '';
		},
		[navigate, searchCase]
	);

	const handleAlertDismiss = () => {
		setShowNotFound(false);
		const searchInput = document.getElementById('header-search');
		if (searchInput instanceof HTMLInputElement) {
			searchInput.focus();
		}
	};

	const handleLogin = () => {
		navigate('/login');
	};

	const handleLogout = async () => {
		try {
			await signOut();
			navigate('/login');
		} catch (error) {
			console.error('Logout failed:', error);
		}
	};

	const renderHeaderInfo = () => {
		return (
			<div className="usa-navbar desktop:padding-bottom-2">
				<Title>
					<div className="display-flex flex-align-end logo-wrapper">
						<Link href={routes.login.path}>
							<Logo showText imageWidth={60} />
						</Link>
					</div>
				</Title>
				<NavMenuButton onClick={onToggleMobileExpanded} label="Menu" />
			</div>
		);
	};

	const renderHeaderLinks = () => {
		const renderDropdownItem = ({ name, path }: (typeof primaryItems)[0]['subItems'][0]) => (
			<Link href={path || '#two'} key={name}>
				<span>{name}</span>
			</Link>
		);

		const renderPrimaryItem = ({ name, subItems }: (typeof primaryItems)[0], index: number) => {
			const isOpen = index === expanded;
			return (
				<Fragment key={name}>
					<NavDropDownButton
						onToggle={(): void => onToggleExtendedHeader(index)}
						menuId={name}
						isOpen={isOpen}
						label={name}
						isCurrent={false}
					/>
					{isOpen &&
						subItems.map(({ name }, index) => (
							<Menu key={name} items={subItems.map(renderDropdownItem)} isOpen={!index} id={name} />
						))}
				</Fragment>
			);
		};

		const renderSecondaryItem = ({ url, name }: (typeof secondaryItems)[0]) => (
			<a href={url} key={name}>
				{name}
			</a>
		);
		const authButton = isAuthenticated ? (
			<Button key="logout" type="button" onClick={handleLogout} className="margin-right-1">
				Log out
			</Button>
		) : (
			<Button key="login" type="button" onClick={handleLogin} className="margin-right-1">
				Log in
			</Button>
		);

		return (
			<span ref={extendedHeaderRef}>
				<ExtendedNav
					primaryItems={primaryItems.map(renderPrimaryItem)}
					secondaryItems={[...secondaryItems.map(renderSecondaryItem), authButton]}
					mobileExpanded={mobileExpanded}
					onToggleMobileNav={onToggleMobileExpanded}
				>
					<Search size="small" onSubmit={onSubmit} />
				</ExtendedNav>
			</span>
		);
	};

	const renderUserInfo = () => {
		if (userInfo) {
			return <div className="user-info">Welcome, {userInfo.name || userInfo.username}</div>;
		}
		return null;
	};

	return (
		<>
			<UswdsHeader extended={true}>
				{renderHeaderInfo()}
				{renderUserInfo()}
				{renderHeaderLinks()}
			</UswdsHeader>
			{showNotFound && (
				<div ref={notFoundAlertRef} tabIndex={-1} role="alert" aria-live="assertive" className="grid-container">
					<Alert type="warning" heading="Case Not Found" headingLevel="h4">
						<p>The case you searched for could not be found. Please check the case number and try again.</p>
						<Button type="button" onClick={handleAlertDismiss}>
							Dismiss
						</Button>
					</Alert>
				</div>
			)}
		</>
	);
};
